












































































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';

@Component
export default class HowDialog extends Vue {
  dialog = false
  loading = false

  get value(): boolean {
    return this.$store.getters.howDialog;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'HowDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (val) {
      this.loading = false;
    }

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'HowDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'how');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onHelpHoneycomb() {
    this.$store.dispatch('openDialog', 'helpHoneycomb');
  }

  onAbout() {
    this.dialog = false;
    this.$store.dispatch('openDialog', 'about');
  }

  onCancel() {
    this.dialog = false;
  }
}
